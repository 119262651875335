@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  .filetree {
    background: constants.$offwhite;
    border: 4px solid constants.$lightgray;

    .file {
      color: constants.$darkgray;
    }

    .children {
      .file {
        &:before {
          border-color: constants.$lightgray;
        }
      }
    }
  }
}
