@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  label.raised, .api-header label {
    color: constants.$white;

    &.api-status-label {
      background-color: constants.$mediumgray;

      &.deprecated, &.security, &.impure-pipe {
        background-color: constants.$brightred;
      }
    }

    &.api-type-label {
      background-color: constants.$accentblue;

      @each $name, $symbol in constants.$api-symbols {
        &.#{$name} {
          background: map-get($symbol, background);
        }
      }
    }

    &.page-label {
      background-color: constants.$mist;
      color: constants.$mediumgray;
    }

    &.property-type-label {
      background-color: constants.$darkgray;
      color: constants.$white;
    }
  }
}
