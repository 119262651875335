@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);

  .nf-response {
    h1 {
      color: if($is-dark-theme, constants.$lightpurple, constants.$purple);
    }
  }

  .nf-icon.material-icons {
    color: constants.$purple;
  }
}
