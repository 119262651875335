@mixin theme($theme) {
  .presskit-container {
    h2 {
      color: #37474F;
    }

    .cc-by-anchor {
      color: grey !important;
    }

    .presskit-row {
      .presskit-inner {
        h3 {
          color: #455A64;
        }

        .transparent-img-bg {
          background-color: #34474F;
        }
      }
    }
  }
}
