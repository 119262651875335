@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $background: map-get($theme, background);
  $is-dark-theme: map-get($theme, is-dark);

  .form-select-button {
    background: if($is-dark-theme, constants.$darkgray, constants.$white);
    box-shadow: 0 2px 2px rgba(constants.$black, 0.24), 0 0 2px rgba(constants.$black, 0.12);
    border: 1px solid if($is-dark-theme, constants.$darkgray, constants.$white);
    color: if($is-dark-theme, constants.$purple-grey-200, constants.$purple-grey-600);

    &:focus {
      border: 1px solid constants.$purple-400;
      box-shadow: 0 2px 2px rgba(constants.$purple-400, 0.24), 0 0 2px rgba(constants.$purple-400, 0.12);
    }

    &[disabled] {
      color: lightgrey;
    }
  }

  .form-select-dropdown {
    background: mat.get-color-from-palette($background, background);
    box-shadow: 0 16px 16px rgba(constants.$black, 0.24), 0 0 16px rgba(constants.$black, 0.12);

    li {
      &:hover {
        background-color: if($is-dark-theme, #444, constants.$purple-grey-50);
      }

      &.selected {
        background-color: if($is-dark-theme, constants.$darkgray, constants.$purple-grey-100);
      }
    }
  }

  .heading {
    color: constants.$darkgray;
  }
}
