@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $background: map-get($theme, background);

  .group-buttons {
    a {
      &.selected {
        background-color: constants.$purple;
        color: constants.$white;
      }
    }
  }

  aio-contributor, ngrx-contributor {
    background: if($is-dark-theme, constants.$deepgray, constants.$white);
    box-shadow: 0 2px 2px rgba(10, 16, 20, 0.24), 0 0 2px rgba(10, 16, 20, 0.12);

    .contributor-info {
      background: rgba(constants.$darkgray, 0.5);

      .info-item {
        color: constants.$white;

        &:hover {
          box-shadow: 0 8px 8px rgba(10, 16, 20, 0.24), 0 0 8px rgba(10, 16, 20, 0.12);
          color: constants.$lightgray;
        }
      }

      [mat-button] {
        color: constants.$white;

        &:hover {
          color: constants.$lightgray;
        }
      }
    }

    .contributor-image {
      border: 2px solid constants.$lightgray;
    }
  }
}
