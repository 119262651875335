@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  aio-search-results {
    &.embedded {
      .search-results {
        .search-area {
          .search-section-header {
            color: constants.$darkgray;
          }

          a {
            color: if($is-dark-theme, constants.$offwhite ,lighten(constants.$darkgray, 10));
            &:hover {
              color: constants.$accentblue;
            }
          }
        }
      }
    }
  }

  .search-results {
    background-color: constants.$darkgray;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.3);

    .search-area {
      .search-section-header {
        color: constants.$white;
      }
    }

    .no-results {
      color:  mat.get-color-from-palette($foreground, text);
    }

    a {
      color:  mat.get-color-from-palette($foreground, text);
    }
  }

  .search-area {
    a {
      color: constants.$lightgray;
      &:hover {
        color: constants.$white;
      }
    }
  }
}
