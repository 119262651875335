@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  // This rule overrides some Angular Material styles defined for `.mat-button`
  // (hence we include `.mat-button` in the selector).
  a.button.mat-button,
  .button {
    // COLORS

    &.button-secondary {
      background: constants.$mediumgray;
      color: rgba(constants.$white, 0.87);
    }

    &.button-plain {
      background: constants.$white;
      color: rgba(constants.$darkgray, 0.87);
    }

    &.button-subtle {
      background: constants.$mediumgray;
      color: darken(constants.$offwhite, 10%);

      &:hover {
        color: rgba(constants.$white, 0.7);
      }
    }

    &.button-blue {
      background: constants.$purple;
      color: rgba(constants.$white, 0.87);

      &:hover {
        color: rgba(constants.$white, 0.7);
      }
    }

    &.button-shield,
    &.button-shield.mat-button {
      background-color: constants.$purple;
      background: constants.$purple url('/projects/ngrx.io/src/assets/images/logos/angular/angular_whiteTransparent.svg') 24px 13px no-repeat;
      @media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2) {
        background: constants.$purple url('/projects/ngrx.io/src/assets/images/logos/angular/angular_whiteTransparent.svg') 24px 13px no-repeat;
        background-size: 22px 22px;
      }
    }

    &.button-banner {
      background: constants.$darkgray;
      color: rgba(constants.$white, 0.87);
    }
  }

  .cta-bar {
    .button {
      &:hover {
        color: constants.$offwhite;
      }
    }
  }

  .group-buttons {
    // This rule overrides some Angular Material styles defined for `.mat-button`
    // (hence we include `.mat-button` in the selector).
    a.button.mat-button.filter-button {
      border: 2px solid constants.$purple;
      background-color: rgba(constants.$purple, 0.2);
      &.selected,
      &:hover {
        background-color: constants.$purple;
        color: constants.$white;
      }
    }
  }
}
