@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $primary: map-get($theme, primary);
  $foreground: map-get($theme, foreground);
  $is-dark-theme: map-get($theme, is-dark);

  .background-sky {
    color: mat.get-color-from-palette($foreground, text);
  }

  section#intro {
    color: constants.$white;

    .hero-logo {
      background-image: url(/assets/images/logos/angular/angular.svg);
    }
  }

  .announcement-bar {
    background-color: rgba(255, 255, 255, 0.1);

    .button {
      color: constants.$white;
      background-color: constants.$purple;

      &:hover {
        color: rgba(constants.$white, 0.7);
      }
    }
  }

  .home-row .card {
    background-color: if($is-dark-theme, constants.$darkgray, constants.$white);

    .card-text-container {
      p {
        color: if($is-dark-theme, constants.$offwhite, constants.$darkgray);
      }
    }

    &:hover {
      h2 {
        color: constants.$purple
      }
    }
  }

  .button.hero-cta {
    background-color: if($is-dark-theme, constants.$darkgray, constants.$white);
  }

  // Verify
  .cta-bar {
    .hero-cta {
      color: constants.$purple
    }
  }

  // Verify
  .text-headline {
    color: if($is-dark-theme, constants.$darkgray, constants.$white);
  }

  .marketing-banner {
    background-color: lighten(constants.$purple, 10);

    .banner-headline {
      color: constants.$white;
    }
  }

  aio-shell {
    &.page-home {
      article {
        color: if($is-dark-theme, constants.$darkgray, constants.$white);
        background-color: constants.$purple;
      }
    }

    &.page-home, &.page-resources, &.page-events, &.page-contribute {
      color: if($is-dark-theme, constants.$darkgray, constants.$white);;
    }
  }
}
