@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);

  .card-container {
    .docs-card {
      background-color: if($is-dark-theme, constants.$darkgray, constants.$white);

      section {
        color: if($is-dark-theme, constants.$offwhite, constants.$darkgray);
      }

      p {
        color: if($is-dark-theme, constants.$offwhite, constants.$darkgray);
      }

      .card-footer {
        color: if($is-dark-theme, constants.$lightgray, constants.$mediumgray);
        border-top: 0.5px solid if($is-dark-theme, constants.$darkgray, constants.$lightgray);

        a {
          color: constants.$mediumgray;
        }
      }

      &:hover {
        section {
          color: constants.$purple;
        }

        p {
          color: constants.$darkgray;
        }

        .card-footer {
          background-color: rgba(constants.$purple, 0.1);
          color: constants.$purple;
        }
      }
    }
  }

  .card-section {
    &:hover {
      box-shadow: 0 2px 2px rgba(constants.$black, 0.24), 0 0 2px rgba(constants.$black, 0.12);
    }
  }
}
