@use '../../constants' ;
@use '@angular/material' as mat;

@mixin theme($theme) {
  $background: map-get($theme, background);
  $is-dark-theme: map-get($theme, is-dark);

  body{
    background: if($is-dark-theme,  mat.get-color-from-palette($background, background), constants.$offwhite);
  }

  @if $is-dark-theme {
    :root {
      color-scheme: dark;
    }
  }
}
