@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);

  hr {
    background: if($is-dark-theme, constants.$lightpurple, #36203A);
  }
}
