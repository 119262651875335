@use '../../constants';
@use '@angular/material' as mat;

@mixin theme($theme) {
  .content {
    figure {
      background: constants.$white;
      box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, .2);
    }
  }
}
